import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @author Anderson Acevedo Briñez
 * @updateBy Sebastian Rios E.
 * @version: jdesk_1.01.0002
 * CustomConsultarMovimientos
**/
class CustomConsultarMovimientos extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm = this.initForm.bind(this);
       this.arrayObjetos=new Map();
       this.seguirGrabar = false;
       this.arrayCampos=new Map();
       this.bHayErrores=false;
       this.bHayError=false;
       this.datosTablagregar={ datos:{}};
       this.gridOptionsComponentes = Object.assign({},this.gridOptions);
       this.gridOptionsTablaConsulta = Object.assign({},this.gridOptions);
       this.gridOptionsTablaConsulta['onSelectionChanged']= this.onSelectionChangedRowTableMov;
       this.datosTabla   = [];
       this.datosO   = [];
       this.propsActuales   = [];
       this.contador=0;
       this.numero_busquedas=0;
       this.contador_busquedas=0;
       this.valores_negativos=0;
       this.valores_positivos=0;
       this.posicion=0;
       this.gridOptionsTerceros                   = Object.assign({},this.gridOptions);
       this.gridOptionsTerceros['onSelectionChanged']= this.onSelectionChangedTerceros;


       this.validarCampos=this.validarCampos.bind(this);

       this.consultarConNumero=this.consultarConNumero.bind(this);
       this.successConsultar=this.successConsultar.bind(this);

       this.consultarSinNumero=this.consultarSinNumero.bind(this);

       this.armarDatos=this.armarDatos.bind(this);

       this.dibujarTabla=this.dibujarTabla.bind(this);

       this.setButtonDetalleActual=this.setButtonDetalleActual.bind(this);

       this.detalleActualChange=this.detalleActualChange.bind(this);

       this.setButtonClone=this.setButtonClone.bind(this);

       this.setButtonValorActual=this.setButtonValorActual.bind(this);

       this.valorActualChange=this.valorActualChange.bind(this);

       this.setButtonBaseActual=this.setButtonBaseActual.bind(this);

       this.baseActualChange=this.baseActualChange.bind(this);

       this.setButtonBaseActual=this.setButtonBaseActual.bind(this);

       this.fechaActualChange=this.fechaActualChange.bind(this);

       this.setButtonNumeroActual=this.setButtonNumeroActual.bind(this);

       this.numeroDocumentoChange=this.numeroDocumentoChange.bind(this);

       this.setButtonSedeActual=this.setButtonSedeActual.bind(this);

       this.setButtonFuenteActual=this.setButtonFuenteActual.bind(this);

       this.fuenteActualChange=this.fuenteActualChange.bind(this);

       this.setButtonCuentaActual=this.setButtonCuentaActual.bind(this);

       this.cuentaActualChange=this.cuentaActualChange.bind(this);

       this.comprobarCuenta=this.comprobarCuenta.bind(this);
       this.successComprobarCuenta=this.successComprobarCuenta.bind(this);

       this.setButtonNitActual=this.setButtonNitActual.bind(this);

       this.nitYsedeChange=this.nitYsedeChange.bind(this);

       this.traerNitYSede=this.traerNitYSede.bind(this);
       this.succesTraerNitYSede=this.succesTraerNitYSede.bind(this);

       this.onSelectionChangedTerceros=this.onSelectionChangedTerceros.bind(this);

       this.onSelectionChangedRowTableMov=this.onSelectionChangedRowTableMov.bind(this);

       this.guardarValor=this.guardarValor.bind(this);
       this.successGuardarValor=this.successGuardarValor.bind(this);

       this.clonarCampo=this.clonarCampo.bind(this);
       this.successClonarCampo=this.successClonarCampo.bind(this);

       this.ocultarTabla=this.ocultarTabla.bind(this);

       this.validarFormatoFecha=this.validarFormatoFecha.bind(this);

       this.existeFecha=this.existeFecha.bind(this);

       this.validarCamposPdf=this.validarCamposPdf.bind(this);

       this.generarPDF=this.generarPDF.bind(this);

       
       this.validarFechas=this.validarFechas.bind(this);
       this.setButtonCodCenCost=this.setButtonCodCenCost.bind(this);

       this.codigoCentroCostoChange=this.codigoCentroCostoChange.bind(this);


       this.guardarNitSede=this.guardarNitSede.bind(this);
       this.successGrabarNitSedeActual=this.successGrabarNitSedeActual.bind(this);
       this.comprobarCentroCosto=this.comprobarCentroCosto.bind(this);
       this.successCentroCosto=this.successCentroCosto.bind(this);

       this.id_data           = 0;
       this.fechaNit ='';

       this.consultarConReferencia = this.consultarConReferencia.bind(this);

       this.consultaBloqueoFechas = this.consultaBloqueoFechas.bind(this);
       this.successConsultaBloqueoFechas = this.successConsultaBloqueoFechas.bind(this);
       this.abrirImprimir          = this.abrirImprimir.bind(this);
       this.habilitarCorreo = this.habilitarCorreo.bind(this);
       this.deshabilitarCorreo = this.deshabilitarCorreo.bind(this);
       this.procesar = this.procesar.bind(this);
       this.generarExcel   = this.generarExcel.bind(this);
       this.enviarCorreo   = this.enviarCorreo.bind(this);
       this.successEnviarCorreo   = this.successEnviarCorreo.bind(this);
       this.generarTxt         = this.generarTxt.bind(this);

    }

    initForm() {

       console.log("Formulario CustomConsultarMovimientos,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez, @updateBy: Sebastian Rios E.");

       
       this.arrayCampos.set(2,'consultar_desde');
       this.arrayCampos.set(3,'consultar_hasta');
       this.arrayCampos.set(4,'consultar_numero_documento');
       this.arrayCampos.set(5,'consultar_codigo_comprobante');
       this.arrayCampos.set(6,'consultar_nombre_comprobante');
       this.arrayCampos.set(7,'consultar_comprobante_id');
       this.arrayCampos.set(9,'generar');
       this.arrayCampos.set(10,'consultar');
       this.arrayCampos.set(12,'tabla_movimientos');
       this.arrayCampos.set(13,'total_negativos');
       this.arrayCampos.set(14,'total_positivos');
       this.arrayCampos.set(15,'total_total_diferencia');
       this.arrayCampos.set(17,'tabla_nit_sede');
       this.arrayCampos.set(18,'modal_movimientos');

       this.arrayObjetos.set(1,'.');
       this.arrayObjetos.set(2,'consultar_desde');
       this.arrayObjetos.set(3,'consultar_hasta');
       this.arrayObjetos.set(4,'consultar_numero_documento');
       this.arrayObjetos.set(5,'consultar_codigo_comprobante');
       this.arrayObjetos.set(6,'consultar_nombre_comprobante');
       this.arrayObjetos.set(7,'consultar_comprobante_id');
       this.arrayObjetos.set(9,'generar');
       this.arrayObjetos.set(10,'consultar');
       this.arrayObjetos.set(12,'tabla_movimientos');
       this.arrayObjetos.set(13,'total_negativos');
       this.arrayObjetos.set(14,'total_positivos');
       this.arrayObjetos.set(15,'total_total_diferencia');
       this.arrayObjetos.set(17,'tabla_nit_sede');
       this.arrayObjetos.set(18,'modal_movimientos');
       this.arrayObjetos.set(86,'.');
       this.arrayObjetos.set(85,'.');
       this.establecerPropiedades('9','visible','false');

       this.getField('consultar').setClick(this.consultaBloqueoFechas);
       this.getField('generar').setClick(this.abrirImprimir);
       this.getField('generar_archivo').setClick(this.procesar);
       this.getField('consultar_nombre_comprobante').setDisabled(true);
       this.getField('consultar_nombre_comprobante').setOnChange(this.ocultarTabla);
       this.getField('consultar_comprobante_id').setOnChange(()=>{if(this.getField('consultar_codigo_comprobante').valid() && this.getField('consultar_comprobante_id').getValue()!==''){this.getField('consultar_nombre_comprobante').setError(false,'');}});


       this.getField('consultar_desde').setOnChange(this.ocultarTabla);
       this.getField('consultar_hasta').setOnChange(this.ocultarTabla);
       this.getField('consultar_numero_documento').setOnChange(this.ocultarTabla);
       this.getField('referencia').setOnChange(this.ocultarTabla);
       this.getField('modal_movimientos').setCloseButton(()=>{this.validarCampos();});

       this.onBtNextPage = this.onBtNextPage.bind(this);
       this.onBtPreviusPage = this.onBtPreviusPage.bind(this);

       //this.getField('consultar_comprobante_id').setOnChange(this.validarComprobante);
       this.getField('tabla_movimientos').setOnBtnNext(this.onBtNextPage);
       this.getField('tabla_movimientos').setOnBtnPrevius(this.onBtPreviusPage);
       this.getField("tabla_movimientos").disabledButtons(true);

        ///Pdf y excel

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_txt').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_txt').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_txt').setOnChange((props)=>{
            if(this.getField('ch_txt').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='txt';
                this.habilitarCorreo();
            }
        });

        this.getField('radio_correo').setOnChange((props) => {
            if (this.getField('radio_correo').getValue() === 'N') {
                this.getField('email').setDisabled(true);
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
            } else {
                this.getField('email').setDisabled(false);
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
            }
        });

    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    //*** definir los botones y los success
    consultaBloqueoFechas(){
        if (this.getField('consultar_desde').valid() && this.getField('consultar_hasta').valid()) {
            this.getField('consultar').setDisabled(true);
            let datos = { 
                datos: { 
                    codigo_sucursal: JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
                    fecha_desde: new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0],
                    fecha_hasta: new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0]
                }
            };

            this.generalFormatPmv = { tipo_servicio: 'cont-bloqueofechas', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successConsultaBloqueoFechas,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage : false
            });
        }
    }

    successConsultaBloqueoFechas(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            let xson = data.data[0].xson;
            if (xson === 1) {
                this.validarCampos();
            }else{
                this.getField('consultar').setDisabled(false);
                this.alertGeneral.toggle(true,'No configurado en bloqueo_fechas',"error");
            }
        }else{
            this.getField('consultar').setDisabled(false);
        }
    }

    
    validarCampos()
    {
            this.getField('consultar_numero_documento').setError(false,'');
            this.getField('referencia').setError(false,'');
            
            this.contador_busquedas=0;
             this.contador=0;
             this.datosO=[];
             this.datosTabla=[];
             if(this.getField('consultar_hasta').valid() && this.getField('consultar_desde').valid() && this.getField('consultar_codigo_comprobante').valid() && this.getField('consultar_nombre_comprobante').valid()) {
               if(this.getField('consultar_hasta').getValue()>=this.getField('consultar_desde').getValue()){
                  let fecha= new Date();
                  if(parseInt(this.getField('consultar_hasta').getValue().substring(0,4))>fecha.getFullYear()){
                     this.numero_busquedas=fecha.getFullYear()-parseInt(this.getField('consultar_desde').getValue().substring(0,4));
                  }
                  else{
                     this.numero_busquedas=parseInt(this.getField('consultar_hasta').getValue().substring(0,4))-parseInt(this.getField('consultar_desde').getValue().substring(0,4));
                  }
                    if(this.getField('consultar_numero_documento').getValue() !== '' && this.getField('consultar_numero_documento').valid() ){
                           this.consultarConNumero();
                     } else if( this.getField('referencia').getValue()!=='' && this.getField('referencia').valid()) {
                        this.consultarConReferencia();
                        }
                     else{
                        this.getField('consultar_numero_documento').valid(false); 
                        this.getField('referencia').valid(false);
                        this.consultarSinNumero();
                     }
               }
               else{ 
                    this.getField('consultar').setDisabled(false);
                    this.getField('consultar_hasta').setError(true,'* El campo Fecha hasta debe ser mayor a Fecha desde.');
               }
            }
            else{
                this.getField('consultar').setDisabled(false);
                this.alertGeneral.toggle(true,'Es necesario llenar los campos requeridos.',"error");
            }
    }

    consultarConNumero()
    {
       let datos={ datos: { value:''+(parseInt(this.getField('consultar_desde').getValue().substring(0,4))+this.contador_busquedas), 
                consultar_codigo_comprobante: this.getField('consultar_codigo_comprobante').getValue(), 
                consultar_numero_documento: this.getField('consultar_numero_documento').getValue(),
                referencia: this.getField('referencia').getValue(),
                consultar_desde: this.getField('consultar_desde').getValue(), 
                consultar_hasta: this.getField('consultar_hasta').getValue(),
        }};
        

            this.getField("tabla_movimientos").setTipoServicioOperacion('cont-cnsltmovimiento-1_3');
            this.getField("tabla_movimientos").sendRequest(this.successConsultar, datos); 
    }

    successConsultar(data)
    {
        this.getField('consultar').setDisabled(false);
        if (data.estado_p === 200)
        {
            this.datosO=data.data;
            this.contador=0;
            this.datosTabla = [];
            this.armarDatos();
        }else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    consultarSinNumero()
    {
       let datos={ datos: { value:''+(parseInt(this.getField('consultar_desde').getValue().substring(0,4))+this.contador_busquedas), consultar_codigo_comprobante: this.getField('consultar_codigo_comprobante').getValue().substring(0,4), consultar_desde: this.getField('consultar_desde').getValue(), consultar_hasta: this.getField('consultar_hasta').getValue(),
}};

            this.getField("tabla_movimientos").setTipoServicioOperacion('cont-cnsltmovimiento-1_4');
            this.getField("tabla_movimientos").sendRequest(this.successConsultar, datos); 
    }


    consultarConReferencia(){
        let datos={ 
                datos: { 
                    value:''+(parseInt(this.getField('consultar_desde').getValue().substring(0,4))+this.contador_busquedas), 
                    consultar_codigo_comprobante: this.getField('consultar_codigo_comprobante').getValue().substring(0,4), 
                    consultar_desde: this.getField('consultar_desde').getValue(), 
                    consultar_hasta: this.getField('consultar_hasta').getValue(),
                    referencia: this.getField('referencia').getValue()
                }};

            this.getField("tabla_movimientos").setTipoServicioOperacion('cont-cnsltmovimiento-1_5');
            this.getField("tabla_movimientos").sendRequest(this.successConsultar, datos); 
    }

    armarDatos()
    {
        
            if(this.datosO.length>0){
                if(this.contador===0){
                    this.establecerPropiedades('9,13,14,15','visible','true');
                    this.getField('tabla_movimientos').toggle(true);
                }
                if(this.contador<this.datosO.length){
                    let datos={};
                    datos['id']= this.datosO[this.contador].id;
                    datos['fecha_documento']= this.datosO[this.contador].fecha_documento;
                    datos['numero_documento']= this.datosO[this.contador].numero_documento;
                    datos['comprobante']= this.datosO[this.contador].codigo_documento;
                    datos['nit']= this.datosO[this.contador].nit;
                    datos['sede']= this.datosO[this.contador].sede;
                    datos['detalle']= this.datosO[this.contador].detalle;
                    datos['base']= this.datosO[this.contador].base;
                    datos['valor']= this.datosO[this.contador].valor;
                    datos['codigo_cuenta']= this.datosO[this.contador].codigo_cuenta;
                    datos['referencia']= this.datosO[this.contador].referencia;
                    datos['documento_fuente']= this.datosO[this.contador].documento_fuente;
                    datos['codigo_centro_costo']= this.datosO[this.contador].codigo_centro_costo;
                    datos['codigo_departamento_interno']= this.datosO[this.contador].codigo_departamento_interno;
                    datos['movimiento_id']= this.datosO[this.contador].id;
                    datos['tipo_fuente']= this.datosO[this.contador].tipo_fuente;
                    datos['vence']= '';
                    datos['id_cartera']= '';
                    datos['nombre']= this.datosO[this.contador].nombre;
                    datos['nombre_cuenta']= this.datosO[this.contador].nombre_cuenta;
                    datos['codigo_usuario']= 'gda';
                    datos['codigo_sucursal']= this.datosO[this.contador].codigo_sucursal;
                    this.datosTabla.push(datos);
                    this.contador+=1;
                    this.armarDatos();
                }
                else{
                    this.dibujarTabla();
                }
            }
            else{
                this.establecerPropiedades('9,13,14,15','visible','false');
                this.getField('tabla_movimientos').toggle(false);
            }
    }


    dibujarTabla()
    {
        try {
                this.propsActuales=[];
                this.gridOptionsTablaConsulta       = Object.assign({},this.gridOptions)
                this.gridOptionsTablaConsulta['onSelectionChanged']= this.onSelectionChangedRowTableMov;
                this.gridOptionsTablaConsulta['rowData'] = [];
                this.getField('tabla_movimientos').initData(this.gridOptionsTablaConsulta);
                this.valores_negativos = 0;
                this.valores_positivos = 0;
                let configCell = new Map();
                configCell.set(0,{cellRenderer:this.setButtonClone,width:90,sortable:false,filter:false,field:'clonar'});
                configCell.set('valor',{cellRenderer:this.setButtonValorActual,cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0,86,201)'},onCellValueChanged:this.valorActualChange,width:100,editable:true,field:'valor'});
                configCell.set('base',{cellRenderer:this.setButtonBaseActual,cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0,86,201)'},onCellValueChanged:this.baseActualChange,width:100,editable:true,field:'base'});
                configCell.set('fecha_documento',{cellRenderer:this.setButtonFechaActual,cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)' },onCellValueChanged:this.fechaActualChange,width:100,editable:true, field:'fecha_documento'});
                configCell.set('numero_documento',{cellRenderer:this.setButtonNumeroActual,cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'}, onCellValueChanged:this.numeroDocumentoChange,width:100,editable:true, field:'numero_documento'}); 
                configCell.set('codigo_documento',{cellRenderer:this.setButtonCodigoActual,width:120,field:'comprobante'});
                configCell.set('sede',{cellRenderer:this.setButtonSedeActual, width:80,field:'sede'});
                configCell.set('documento_fuente',{cellRenderer:this.setButtonFuenteActual,cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0,86,201)'},onCellValueChanged:this.fuenteActualChange,width:160,editable:true,field:'documento_fuente'});
                configCell.set('codigo_cuenta',{cellRenderer:this.setButtonCuentaActual,cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0,86,201)'},onCellValueChanged:this.cuentaActualChange,width:100,editable:true,field:'codigo_cuenta'});
                configCell.set('nit',{cellRenderer:this.setButtonNitActual,cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0,86,201)'},onCellValueChanged:this.nitYsedeChange,width:100,editable:true,field:'nit'});
                configCell.set('detalle',{cellRenderer:this.setButtonDetalleActual,cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"left",borderRadius:"5px",color:'rgb(0,86,201)'},onCellValueChanged:this.detalleActualChange,editable:true,field:'detalle'});
                //configCell.set('codigo_centro_costo',{cellRenderer:this.setButtonCodCenCost,cellStyle: {backgroundColor: "rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'}, onCellValueChanged:this.codigoCentroCostoChange,width: 100,editable: true, field: 'codigo_centro_costo' });
                this.gridOptionsTablaConsulta['rowData'] = this.datosTabla;
                this.getField('tabla_movimientos').initData(this.gridOptionsTablaConsulta,configCell);
                this.getField('total_negativos').setVisible(true);
                this.getField('total_positivos').setVisible(true);
                this.getField('total_total_diferencia').setVisible(true);
                
                for(let datos in this.datosTabla){
                    if(this.datosTabla[datos]['valor'] < 0){
                        this.valores_negativos += this.datosTabla[datos]['valor'];
                    } else {
                        this.valores_positivos += this.datosTabla[datos]['valor'];
                    } 
                }
                this.getField('total_negativos').setValue("".redondear(this.valores_negativos,4));
                this.getField('total_positivos').setValue("".redondear(this.valores_positivos,4));

                this.getField('total_total_diferencia').setValue("".redondear(this.valores_positivos+this.valores_negativos,4));
                
                this.getField('total_positivos').setValue("".redondear(this.valores_positivos,4));
                this.getField('total_total_diferencia').setValue("".redondear(this.valores_positivos+this.valores_negativos,4));
        } catch (error) {
        
            
        }

    }
    onBtNextPage(){
        
    }

    onBtPreviusPage(){
        
    }

    setButtonCodCenCost(props){
      // Una forma de hacerlo:
      if (props.data.documento_fuente !== '.') {

           let button = document.createElement("input");
          button.setAttribute("value",props.data.codigo_centro_costo);
          button.setAttribute("class","buttonDisabled");
          button.setAttribute("type","button");
          button.setAttribute("disabled",true);
          return this.createElementJaivana(button);
      }else{
             let valor_actual = props.data.codigo_centro_costo;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = valor_actual;
             div.appendChild(label);
             return this.createElementJaivana(div);
     
          
      }
  }

  codigoCentroCostoChange(props){
   if (props.data.documento_fuente !== '.') {// Esta condición if se hace, ya que se prevee una acción no permitica en el campo "codigo_centro_costo" de la tabla
       this.alertGeneral.toggle(true,'Acción no permitida','error');
       this.validarCampos();
   }else{
       if(props.newValue !== ''){
           if(props.oldValue+'' !== props.newValue+''){
               //let id = props.data.id;
               let valor_actual = props.newValue;
               //let valor_anterior = props.oldValue;
               //let campo_tabla = "codigo_centro_costo";
               //let codigo = props.data.codigo_documento;
               //let fecha = props.data.fecha_documento.split('-')[0];
               let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,'^[0-9]+$',10);
               if(seguir){
                    if(valor_actual !== ''){
                        //this.guardarValor(id,valor_actual,valor_anterior,campo_tabla,fecha,codigo);
                        this.comprobarCentroCosto(props);
                    }
                    else{
                        this.alertGeneral.toggle(true,'El campo no puede estar vacío',"error");
                        this.validarCampos();
                    }
                }else{
                    this.validarCampos();
                }
           }
       }else{
           this.alertGeneral.toggle(true,'Campo no puede estar vacío',"error");
           this.validarCampos();
       }
   }
}

    comprobarCentroCosto(props)
    {
       let datos={ datos: { value:props.data.codigo_centro_costo }};
            this.generalFormatPmv = { tipo_servicio: 'cont-centrosdecosto', operacion: '42', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success:(data) => this.successCentroCosto(data,props),
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successCentroCosto(data,props)
    {
        if (data.estado_p === 200)
        {
          let id = props.data.id;
          let valor_actual = props.newValue;
          let valor_anterior = props.oldValue;
          let campo_tabla = "codigo_centro_costo";
          let codigo = props.data.codigo_documento;
          let fecha = props.data.fecha_documento.split('-')[0];
         
         this.guardarValor(id,valor_actual,valor_anterior,campo_tabla,fecha,codigo);
        }
        else 
        {
           if(data.estado_p===404) {

           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           this.validarCampos();
       }
    }
    setButtonDetalleActual(data)
    {
            let props=data;
             let valor_actual = props.data.detalle;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = valor_actual;
             div.appendChild(label);
             return this.createElementJaivana(div);
    }

    detalleActualChange(props){
      if(props.newValue !== ''){
          if(props.oldValue+'' !== props.newValue+''){
             let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,'^[^\\u0027\\u003F\\u00BF\\u0022\\u002F\\u005C\\u003D]+$',250);
              if(seguir){
                let id = props.data.id;
                let valor_actual = props.newValue;
                let valor_anterior = props.oldValue;
                let campo_tabla = "detalle";
                let codigo = props.data.codigo_documento;
                let fecha = props.data.fecha_documento.split('-')[0];
                if(valor_actual !== '')
                    this.guardarValor(id,valor_actual,valor_anterior,campo_tabla,fecha,codigo);
                else{
                    this.alertGeneral.toggle(true,'El campo no puede estar vacío',"error");
                    this.validarCampos();
                }
            }else{
                this.validarCampos();
            }
          }
      }else{
          this.alertGeneral.toggle(true,'Campo no puede estar vacío',"error");
          this.validarCampos();
      }
  }

    setButtonClone(data)
    {
            let props=data;
             let id = props.data.id;
             //let fecha = props.data.fecha_documento.split('-')[0];
             let button = document.createElement("input"); 
             button.onclick = ()=>this.clonarCampo(props);
             button.setAttribute("id",'button_delete_'+id);
             button.setAttribute("class","buttonStyle");
             button.setAttribute("type","button");
             button.setAttribute("value","+");
             return this.createElementJaivana(button);
    }

    setButtonValorActual(data)
    {
            let props=data;
             let valor_actual = props.data.valor;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = "".formatoPrecio(valor_actual);
             div.appendChild(label);
             if(parseFloat(valor_actual) < 0)
             this.valores_negativos += parseFloat(valor_actual);
             else if(parseFloat(valor_actual) > 0)
             this.valores_positivos += parseFloat(valor_actual);
             return this.createElementJaivana(div);
    }

    valorActualChange(props)
    {
        let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,/^-?(\d{1,13})(\.\d{1,2})?$/,17);
        if(seguir){
            let id = props.data.id;
            let valor_actual = props.newValue;
            let valor_anterior = props.oldValue;
            let campo_tabla = "valor";
            let codigo = props.data.codigo_documento;
            let fecha = props.data.fecha_documento.split('-')[0];

            this.guardarValor(id,parseFloat(valor_actual)?parseFloat(valor_actual):0,parseFloat(valor_anterior)?parseFloat(valor_anterior):0,campo_tabla,fecha,codigo);
        }else if(seguir !== null)
        this.validarCampos();
    }


    baseActualChange(props)
    {
      let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,/^-?(\d{1,13})(\.\d{1,2})?$/,17);
      if(seguir){
          let id = props.data.id;
          let valor_actual = props.newValue;
          let valor_anterior = props.oldValue;
          let campo_tabla = "base";
          let codigo = props.data.codigo_documento;
          let fecha = props.data.fecha_documento.split('-')[0];
          this.guardarValor(id,(parseFloat(valor_actual)?parseFloat(valor_actual):0),(parseFloat(valor_anterior)?parseFloat(valor_anterior):0),campo_tabla,fecha,codigo);
      }else if(seguir !== null)
      this.validarCampos();
    }

    setButtonBaseActual(data)
    {
            let props=data;
             let valor_actual = props.data.base;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = "".formatoPrecio(valor_actual);
             div.appendChild(label);
             return this.createElementJaivana(div);
    }

    fechaActualChange(props){
      let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,'',10);
      if(seguir){
          let id = props.data.id;
          let fecha_actual = props.newValue;
          let fecha_anterior = props.oldValue;
          if(this.validarFormatoFecha(fecha_actual) && this.existeFecha(fecha_actual)){
              if(parseInt(fecha_actual.split('-')[0]) === parseInt(fecha_anterior.split('-')[0])){
                  let campo_tabla = "fecha_documento";
                  let fecha = props.data.fecha_documento.split('-')[0];
                  let codigo = props.data.codigo_documento;
                  this.guardarValor(id,fecha_actual,fecha_anterior,campo_tabla,fecha,codigo);
              }else{
                  this.alertGeneral.toggle(true,'Las fechas deben tener el mismo año',"error");
                  this.validarCampos();
              }
          }else{
              this.alertGeneral.toggle(true,'Fecha incorrecta',"error");
              this.validarCampos();
          }
      }else if(seguir !== null)
      this.validarCampos();
  }

    setButtonNumeroActual(data)
    {
            let props=data;
             let valor_actual = props.data.numero_documento;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = valor_actual;
             div.appendChild(label);
             return this.createElementJaivana(div);
    }

    numeroDocumentoChange(props){
      let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,'^[0-9A-Za-z-]+$',20);
      if(seguir){
          let id = props.data.id;
          let valor_actual = props.newValue;
          let valor_anterior = props.oldValue;
          let campo_tabla = "numero_documento"; 
          let codigo = props.data.codigo_documento;
          let fecha = props.data.fecha_documento.split('-')[0];
          this.guardarValor(id,valor_actual,valor_anterior,campo_tabla,fecha,codigo);
      }else if(seguir !== null)
      this.validarCampos();
  }

    setButtonSedeActual(data)
    {
            let props=data;
             let valor_actual = props.data.sede;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = valor_actual;
             div.appendChild(label);
             return this.createElementJaivana(div);
    }

    setButtonFuenteActual(data)
    {
            let props=data;
             let valor_actual = props.data.documento_fuente;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = valor_actual;
             div.appendChild(label);
             return this.createElementJaivana(div);
    }
 
    fuenteActualChange(props){
      if(props.data.tipo_fuente === 'C' || props.data.tipo_fuente === 'P'){
          let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,'^[0-9a-zA-ZñÑ.-]+$',20);
          if(seguir){
              let id = props.data.id;
              let valor_actual = props.newValue;
              let valor_anterior = props.oldValue;
              let campo_tabla = "documento_fuente";
              let codigo = props.data.codigo_documento;
              let fecha = props.data.fecha_documento.split('-')[0];
              this.guardarValor(id,valor_actual,valor_anterior,campo_tabla,fecha,codigo);
          }else if(seguir !== null)
          this.validarCampos();
      }else{
          this.alertGeneral.toggle(true,'No se puede modificar este dato. Tipo de Fuente no es C o P',"error");
          this.validarCampos();
      }
  }

    setButtonCuentaActual(data)
    {
            let props=data;
             let valor_actual = props.data.codigo_cuenta;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = valor_actual;
             div.appendChild(label);
             return this.createElementJaivana(div);
    }

    cuentaActualChange(data)
    {
            let props=data;
             let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,'^[0-9]+$',10);
             if(seguir){
             this.id_data = props.node.id;
             this.comprobarCuenta(props);}
             else if(seguir !== null)
             this.validarCampos();
    }

    comprobarCuenta(props)
    {
       let datos={ datos: { value:props.data.codigo_cuenta }};
            this.generalFormatPmv = { tipo_servicio: 'maes-cuentas', operacion: '42', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success:(data) => this.successComprobarCuenta(data,props),
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successComprobarCuenta(data,props)
    {
        if (data.estado_p === 200)
        {
         let fecha = this.gridOptionsTablaConsulta.api.getRowNode(this.id_data).data.fecha_documento.split('-')[0];
         let valor_nuevo = props.data.codigo_cuenta;
         let id = props.data.id;
         let valor_anterior=props.oldValue;
         let campo_tabla = 'codigo_cuenta';
         let codigo = props.data.codigo_documento;
         this.guardarValor(id,valor_nuevo,valor_anterior,campo_tabla,fecha,codigo);
        }
        else 
        {
           if(data.estado_p===404) {
            
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           this.validarCampos();
       }
    }

    setButtonNitActual(data)
    {
            let props=data;
             let valor_actual = props.data.nit;
             let div = document.createElement("div");
             let label = document.createElement("label");
             label.textContent = valor_actual;
             div.appendChild(label);
             return this.createElementJaivana(div);
    }

    nitYsedeChange(data)
    {
            let props=data;
             let seguir = this.getField('tabla_movimientos').cellValid(props.oldValue,props.newValue,'^[0-9]+$',20);
             if(seguir){
             this.traerNitYSede(props);}
             else if(seguir !== null)
                this.validarCampos();
    }

    traerNitYSede(data)
    {
       let datos={ datos: { value:data.data.nit, yukonnitsx_estado:'A' }};
            this.generalFormatPmv = { tipo_servicio: 'maes-nitsxsedes', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesTraerNitYSede,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    succesTraerNitYSede(data)
    {
        if (data.estado_p === 200)
        {
            if(data.data.length>1){
                //let props=this.propsActuales;
                this.gridOptionsTerceros     = Object.assign({},this.gridOptions);  
                this.gridOptionsTerceros['rowData'] = [];
                this.getField('tabla_nit_sede').initData(this.gridOptionsTerceros);
                
                this.gridOptionsTerceros['onSelectionChanged']= this.onSelectionChangedTerceros;
                this.gridOptionsTerceros['rowData'] = data.data;
                this.getField('tabla_nit_sede').initData(this.gridOptionsTerceros);
                this.getField('modal_movimientos').handleClickOpen();
            }else{
                let nit = data.data[0].yukonnitsx_nit;
                let sede = data.data[0].yukonnitsx_sede;
                this.guardarNitSede(this.id_data,nit,sede,this.fechaNit);
            }
        }
        else 
        {
           if(data.estado_p===404) {
            
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);

              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           this.validarCampos();
       }
    }

    onSelectionChangedTerceros()
    {

/* 
      let selectedRows = this.gridOptionsTerceros.api.getSelectedRows();
      let props=selectedRows[0];
      let nit = props.yukonnitsx_nit;
      let sede = props.yukonnitsx_sede;
      this.guardarNitSede(this.id_data,nit,sede,this.fechaNit); */
    }

    guardarNitSede(id,nit,sede,fecha){
      let datos={ datos: { 
         id : id,
          anio:fecha,
          nit,
          sede: parseInt(sede)
         }};
      this.generalFormatPmv = { tipo_servicio: 'cont-grabarmvtcontab', operacion: '6_2', operacion_tipo: 'modificar' };
      this.service.send(
      {
               endpoint: this.constant.formConfiguration(),
               method:'PUT',
               body: datos,
               success: this.successGrabarNitSedeActual,
               error: this.error_,
               general: this.generalFormatPmv
      });
   }

   successGrabarNitSedeActual(data){
    this.getField('modal_movimientos').handleClose();
      
  }

    onSelectionChangedRowTableMov()
    {

        try {
            let selectedRows = this.gridOptionsTablaConsulta.api.getSelectedRows();
            
            if(selectedRows.length>0 ){
                let props=selectedRows[0];
                this.posicion= this.datosTabla.indexOf(props)
                this.propsActuales= props; 
                this.id_data=props.id;
                this.fechaNit = props.fecha_documento.split('-')[0];
            }else{
                
                selectedRows = [];
                this.gridOptionsTablaConsulta['onSelectionChanged']= [];
            }
            
        } catch (error) {
            /* console.log('Error Seleccinando una fila de la tabla'); */
        }

    }

    guardarValor(id,valor_new,valor_old,campo_tabla,fecha,codigo_documento)
    {
      //recibe campo_tabla, año ,id movimiento, valor_nuevo
       let datos={ datos: { 
         campo_tabla:campo_tabla, 
         valor_nuevo:valor_new, 
         anio:fecha,
         id:id
         }};
            this.generalFormatPmv = { tipo_servicio: 'cont-grabarmvtcontab', operacion: '6_1', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successGuardarValor,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successGuardarValor(data)
    {
        if (data.estado_p === 200)
        {
            
            this.validarCampos();
        }
        else 
        {
           if(data.estado_p===404) {
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           this.validarCampos();
       }
    }

    clonarCampo(data)
    {
       let datos={ datos: { value:data.data.fecha_documento.split('-')[0], id:data.data.movimiento_id }};
            this.generalFormatPmv = { tipo_servicio: 'cont-grabarmvtcontab', operacion: '5_2', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successClonarCampo,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successClonarCampo(data)
    {
        if (data.estado_p === 200)
        {
            
            this.validarCampos();
        }
        else 
        {
           if(data.estado_p===404) {
                
           }
           else {
             
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    ocultarTabla()
    {
             this.establecerPropiedades('9,13,14,15','visible','false');
             this.getField('tabla_movimientos').toggle(false);
    }

    validarFormatoFecha(data)
    {
            let campo=data;
             let RegExPattern = /^\d{2,4}\u002D\d{1,2}\u002D\d{1,2}$/;
             if ((campo.match(RegExPattern)) && (campo!==''))
             return true;
             else 
             return false;
    }

    existeFecha(data)
    {
            let fecha=data;
             let fechaf = fecha.split("-");
             let day = fechaf[2];
             let month = fechaf[1];
             let year = fechaf[0];
             let date = new Date(year,month,'0');
             if(parseInt(month) > 12)
             return false;
             if(parseInt(month) < 1)
             return false;
             if((day-0)>(date.getDate()-0))
             return false;
             if(day+'' === '0')
             return false;
             if(day === '0_')
             return false;
             if(day === '__')
             return false;
             if(day === '00')
             return false;
             return true;
    }



    validarFechas(fecha1,fecha2){
      let dia = parseInt(fecha2.split('-')[2]);
      let mes = parseInt(fecha2.split('-')[1]);
      let anio = parseInt(fecha2.split('-')[0]);
      //let valid = true;
      let dia2 = parseInt(fecha1.split('-')[2]);
      let mes2 = parseInt(fecha1.split('-')[1]);
      let anio2 = parseInt(fecha1.split('-')[0]);
      if(anio>=anio2){
         if(anio-anio2<=2){
            if(anio-anio2===2){
               if(mes<=mes2){
                  if(dia<=dia2){
                     return true;
                  }else{
                     return false;
                  }
               }else{
                  return true;
               }
            }else{
               return true;
            }
            
         }else{
            return false;
         }
      }else{
         return false;
      }
    }


    validarCamposPdf()
    {
        if(Object.values(this.propsActuales).length>0){
         this.generarPDF();}
       else{
         this.alertGeneral.toggle(true,'Seleccionar un registro de la tabla.',"error");}
    }

    abrirImprimir(){
        if(Object.values(this.propsActuales).length>0){
            this.getField('modal_imprimir').handleClickOpen();
            this.getField('email').setValue('');
            this.getField('email').setError(false,'');
            this.getField('radio_correo').setValue("N");
            this.getField('ch_excel').setValueSwitch(false);
            this.getField('ch_pdf').setValueSwitch(false);
            this.getField('ch_txt').setValueSwitch(false);
            
            this.getField('email').setDisabled(false);    
        }else{
            this.alertGeneral.toggle(true,'Seleccionar un registro de la tabla.',"error");
        }
    }

    procesar(){
        
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_txt').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf, TXT ó Excel', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreo();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.validarCamposPdf();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcel();
                    }else if(this.operacion_actual==='txt'){
                        this.generarTxt();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    generarPDF()
    {
        let url = this.constant.formConfiguration();
        let datos2={
        codigo_sucursal: this.propsActuales.codigo_sucursal,
        codigo_documento: this.propsActuales.comprobante,
        numero_documento: this.propsActuales.numero_documento,
        fecha:this.propsActuales.fecha_documento}
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-cnsltmovimiento","operacion":"pdf","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    const pdf = new File([blob], 'ConsultaMovimientos.pdf', {
                        type:'application/pdf'});
                    window.open(URL.createObjectURL(pdf));
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarExcel()
    {
        let url = this.constant.formConfiguration();
        let datos2={
        codigo_sucursal: this.propsActuales.codigo_sucursal,
        codigo_documento: this.propsActuales.comprobante,
        numero_documento: this.propsActuales.numero_documento,
        fecha:this.propsActuales.fecha_documento}
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-cnsltmovimiento","operacion":"excel","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'ConsultaMovimiento.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }
    
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    enviarCorreo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos={datos:{
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
            codigo_sucursal: this.propsActuales.codigo_sucursal,
            codigo_documento: this.propsActuales.comprobante,
            numero_documento: this.propsActuales.numero_documento,
            fecha:this.propsActuales.fecha_documento
        }}
        

        this.generalFormatPmv = { tipo_servicio: 'cont-cnsltmovimiento', operacion: this.operacion_actual, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarTxt()
    {
        let url = this.constant.formConfiguration();
        let datos2={
            codigo_sucursal: this.propsActuales.codigo_sucursal,
            codigo_documento: this.propsActuales.comprobante,
            numero_documento: this.propsActuales.numero_documento,
            fecha:this.propsActuales.fecha_documento
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-cnsltmovimiento","operacion":"txt","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successValidar(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'ConsultaMovimientos.txt';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    //*** fin  los botones y los success

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }


   cargarCampos(data) 
   {
          this.getField('consultar_desde').setValue(data.data[0].consultar_desde);
          this.getField('consultar_hasta').setValue(data.data[0].consultar_hasta);
          this.getField('consultar_numero_documento').setValue(data.data[0].consultar_numero_documento);
          this.getField('referencia').setValue(data.data[0].referencia);
          this.getField('consultar_codigo_comprobante').setValue(data.data[0].consultar_codigo_comprobante);
          this.getField('consultar_nombre_comprobante').setValue(data.data[0].consultar_nombre_comprobante);
          this.getField('consultar_comprobante_id').setValue(data.data[0].consultar_comprobante_id);
          this.getField('consultar').setValue(data.data[0].consultar);
          this.getField('tabla_movimientos').setValue(data.data[0].tabla_movimientos);
          this.getField('total_negativos').setValue(data.data[0].total_negativos);
          this.getField('total_positivos').setValue(data.data[0].total_positivos);
          this.getField('total_total_diferencia').setValue(data.data[0].total_total_diferencia);
          this.getField('tabla_nit_sede').setValue(data.data[0].tabla_nit_sede);
          this.getField('modal_movimientos').setValue(data.data[0].modal_movimientos);
   }

}
FormJaivana.addController('cont-cnsltmovimiento',CustomConsultarMovimientos);
export default CustomConsultarMovimientos;